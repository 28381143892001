var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: true,
            "show-close": false,
            width: "600px",
            title: _vm.title
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isBusy,
                  expression: "isBusy"
                }
              ],
              attrs: { "element-loading-spinner": "el-icon-loading" }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.node,
                        "label-position": "left",
                        "label-width": "100px",
                        size: "small",
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isPropositionVisible,
                              expression: "isPropositionVisible"
                            }
                          ]
                        },
                        [
                          _c("h6", [_vm._v("Proposition")]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Name", prop: "name" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.node.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.node, "name", $$v)
                                  },
                                  expression: "node.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Context", prop: "context" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.node.context,
                                  callback: function($$v) {
                                    _vm.$set(_vm.node, "context", $$v)
                                  },
                                  expression: "node.context"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Message", prop: "message" } },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", rows: 4 },
                                model: {
                                  value: _vm.node.message,
                                  callback: function($$v) {
                                    _vm.$set(_vm.node, "message", $$v)
                                  },
                                  expression: "node.message"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isOutcomeVisible,
                              expression: "isOutcomeVisible"
                            }
                          ]
                        },
                        [
                          _c("h6", [_vm._v("Outcome")]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Context",
                                prop: "outcome_context"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.node.outcome_context,
                                  callback: function($$v) {
                                    _vm.$set(_vm.node, "outcome_context", $$v)
                                  },
                                  expression: "node.outcome_context"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Message", prop: "outcome" } },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", rows: 4 },
                                model: {
                                  value: _vm.node.outcome,
                                  callback: function($$v) {
                                    _vm.$set(_vm.node, "outcome", $$v)
                                  },
                                  expression: "node.outcome"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.onSaveButtonClicked } }, [
                    _vm._v("Save")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { on: { click: _vm.onCancelButtonClicked } },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }