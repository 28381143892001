<template>
    <div>
        <div class="decision-tree-container" v-dragscroll.x ref="container">
            <div class="decision-tree-content">
                <decision-tree-group
                        v-if="isGroupVisible"
                        :proposition="root"
                        @tree-node-selected="onTreeNodeSelected"
                ></decision-tree-group>
                <add-item-placeholder
                        v-if="isAddRootPlaceholderVisible"
                        @click.native="addRootProposition"
                ></add-item-placeholder>
            </div>
        </div>
        <item-form v-if="isEditMode"></item-form>
    </div>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex';
  import decisionTreeStoreModule from './../../store/decision-tree.module';
  import AddItemPlaceholder from './add-item-placeholder';
  import ItemForm from './item-form';
  import {dataStatuses} from "../../store/decision-tree.types";

  const {mapState, mapActions, mapMutations, mapGetters} = createNamespacedHelpers('decisionTree');

  export default {
    props: {
      params: Object,
    },

    watch: {
      path: function () {
        this.$nextTick(function () {
          this.scrollToCurrent();
        });
      },
    },

    computed: {
      ...mapState(['path', 'dom']),
      ...mapGetters(['root', 'isEditMode', 'isDataStatus']),

      hasProposition: function () {
        return !_.isEmpty(this.root);
      },

      isGroupVisible() {
        return this.isDataStatus(dataStatuses.fetched) && this.hasProposition;
      },

      isAddRootPlaceholderVisible() {
        return this.isDataStatus(dataStatuses.fetched)
            && !this.hasProposition
            && this.isEditMode;
      }
    },

    beforeCreate() {
      this.$store.registerModule('decisionTree', decisionTreeStoreModule);
    },

    mounted() {
      this.initStore(this.params);
      this.$root.$on('decision-tree-to-current', this.scrollToCurrent);
    },

    beforeDestroy() {
      this.$root.$off('decision-tree-to-current', this.scrollToCurrent);
    },

    methods: {
      ...mapActions({initStore: 'init'}),
      ...mapMutations(['setState']),

      /**
       * @param {Object} state
       */
      onTreeNodeSelected({ids}) {
        this.setState({
          ids: _.reverse(ids),
        });
      },

      /**
       * @return {undefined}
       */
      scrollToCurrent() {
        if (this.dom) {
          this.$scrollTo(this.dom, 700, {container: this.$refs.container});
        }
      },

      /**
       * @return {undefined}
       */
      addRootProposition() {
        this.$root.$emit('show-decision-tree-item-form', {
          type: 'proposition',
          node: {
            parent_id: null,
          },
        });
      },
    },

    components: {
      'add-item-placeholder': AddItemPlaceholder,
      'item-form': ItemForm,
    },
  }
</script>