<template>
    <div>
        <button class="btn btn-purple" @click="stepBack">Step Back</button>
        <button class="btn btn-purple" @click="toCurrent">To Active</button>
    </div>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex';

  const {mapActions} = createNamespacedHelpers('decisionTree');

  export default {
    methods: {
      ...mapActions(['stepBack']),

      /**
       * @return undefined
       */
      toCurrent() {
        this.$root.$emit('decision-tree-to-current');
      },
    }
  }
</script>