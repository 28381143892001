import Axios from "axios/index";
import {updateUrl, validateIds} from "./decision-tree.helpers";
import {dataStatuses, modes} from "./decision-tree.types";

const state = {
  indexNodeUrl: null,
  createNodeUrl: null,
  updateNodeUrl: null,
  destroyNodeUrl: null,
  dataStatus: dataStatuses.notFetched,
  path: [],
  dom: null,
  mode: null,
  nodes: [],
};

const getters = {
  getChildren: state => (node) => {
    return node
      ? _.filter(state.nodes, {parent_id: node.id})
      : [];
  },

  root: (state, getters) => {
    return _.first(getters.getChildren({id: null}));
  },

  isEditMode: state => {
    return state.mode === modes.edit;
  },

  isDisplayMode: state => {
    return state.mode === modes.display;
  },

  isDataStatus: state => status => {
    return state.dataStatus === status;
  },

  isNodeSelected: state => ({id}) => {
    return _.includes(state.path, id);
  },

  isNodeCurrent: state => (node) => {
    if (_.isEmpty(node)) {
      return false;
    }
    if (!_.isEmpty(state.path)) {
      return _.last(state.path) === node.id;
    }

    return node.parent_id === null;
  },

  storeNodeUrl: state => id => {
    return id
      ? _.replace(state.updateNodeUrl, ':proposition', id)
      : state.createNodeUrl;
  },

  destroyNodeUrl: state => id => {
    return _.replace(state.destroyNodeUrl, ':proposition', id);
  },
};

const mutations = {
  setState(state, {ids}) {
    state.path = [...ids];
    updateUrl({path: [...state.path]});
  },

  setDomElement(state, dom) {
    state.dom = dom;
  },

  setMode(state, mode) {
    if (_.includes(modes, mode)) {
      state.mode = mode;
    }
  },

  setDataStatus(state, status) {
    if (_.includes(dataStatuses, status)) {
      state.dataStatus = status;
    }
  },

  setIndexNodeUrl(state, url) {
    state.indexNodeUrl = url;
  },

  setCreateNodeUrl(state, url) {
    state.createNodeUrl = url;
  },

  setUpdateNodeUrl(state, url) {
    state.updateNodeUrl = url;
  },

  setDestroyNodeUrl(state, url) {
    state.destroyNodeUrl = url;
  },

  setNodes(state, nodes) {
    state.nodes = nodes;
  },
};

const actions = {
  init({commit, state, dispatch}, {path, mode, indexNodeUrl, createNodeUrl, updateNodeUrl, destroyNodeUrl}) {
    commit('setMode', mode);
    commit('setIndexNodeUrl', indexNodeUrl);
    commit('setCreateNodeUrl', createNodeUrl);
    commit('setUpdateNodeUrl', updateNodeUrl);
    commit('setDestroyNodeUrl', destroyNodeUrl);
    dispatch('prefetch').then(() => dispatch('setInitialState', {ids: path}));
  },

  setInitialState({commit, getters, state}, {ids}) {
    if (_.isEmpty(ids)) {
      commit('setState', {ids: []});
    }

    const validIds = validateIds(
      getters.root, _.map(ids, _.parseInt), state.nodes
    );

    commit('setState', {
      ids: validIds
    });
  },

  prefetch({commit, state}) {
    commit('setDataStatus', dataStatuses.fetching);

    return Axios.get(state.indexNodeUrl)
      .then(res => {
        commit('setNodes', res.data.data);
        commit('setDataStatus', dataStatuses.fetched);
      })
      .catch(err => {
        commit('setDataStatus', dataStatuses.fetchingError);
        commit('setNodes', []);
      });
  },

  store({commit, state, getters}, {form, id}) {
    return Axios.post(getters.storeNodeUrl(id), form)
      .then(res => commit('setNodes', res.data.data))
      .catch(err => Promise.reject(err.response));
  },

  destroy({commit, getters}, {id}) {
    return Axios.delete(getters.destroyNodeUrl(id))
      .then(res => commit('setNodes', res.data.data))
      .catch(err => Promise.reject(err.response.data));
  },

  stepBack({commit, state}) {
    commit('setState', {
      ids: _.initial([...state.path]),
    });
    commit('setDomElement', null);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}