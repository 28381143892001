window.Vue = require('vue');

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import VueDragscroll from 'vue-dragscroll';
import VueScrollTo from 'vue-scrollto';
import VueClipboards from 'vue-clipboards';
import DecisionTree from './components/decision-tree/decision-tree';
import DecisionTreeControl from './components/decision-tree-control';
import DecisionTreeGroup from './components/decision-tree/decision-tree-group';
import store from './store/index';

Vue.use(ElementUI, {locale});
Vue.use(VueDragscroll);
Vue.use(VueScrollTo, {easing: 'ease-out', x: true, y: false});
Vue.use(VueClipboards);

Vue.component('decision-tree', DecisionTree);
Vue.component('decision-tree-group', DecisionTreeGroup);
Vue.component('decision-tree-control', DecisionTreeControl);

export default new Vue({
  el: '#app',
  store,
});



