var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "dragscroll",
              rawName: "v-dragscroll.x",
              modifiers: { x: true }
            }
          ],
          ref: "container",
          staticClass: "decision-tree-container"
        },
        [
          _c(
            "div",
            { staticClass: "decision-tree-content" },
            [
              _vm.isGroupVisible
                ? _c("decision-tree-group", {
                    attrs: { proposition: _vm.root },
                    on: { "tree-node-selected": _vm.onTreeNodeSelected }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isAddRootPlaceholderVisible
                ? _c("add-item-placeholder", {
                    nativeOn: {
                      click: function($event) {
                        return _vm.addRootProposition($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.isEditMode ? _c("item-form") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }