<template>
    <div class="add-root-placeholder border text-center">
        <i class="fas fa-plus-circle fa-2x"></i>
    </div>
</template>

<script>
  export default {

  }
</script>

<style scoped>

</style>