<template>
    <div
            class="column-item proposition"
            v-bind:class="{current:isCurrent}"
    >
        <div class="clearfix">
            <div class="control-panel float-right" v-if="isEditMode">
                <el-button @click.stop="edit" type="text" class="p-0"><i class="fas fa-edit fa-lg"></i></el-button>
            </div>
            <div class="proposition-name float-left"><strong>Name:</strong></div>
        </div>
        <p>{{name}}</p>
        <div class="proposition-context">
            <strong>Context:</strong>
            <p>{{context}}</p>
        </div>
        <div class="proposition-message">
            <div class="clearfix">
                <div class="float-left"><strong>Message:</strong></div>
                <div class="float-right">
                    <el-button
                            type="text"
                            v-clipboard="message"
                            @success="onCopySuccess"
                            @error="onCopyError"
                            class="p-1 copy-button"
                            :class="animationClass"
                            @animationend.native="onAnimationEnd"
                    >
                        <i class="fas fa-copy fa-lg"></i>
                    </el-button>
                </div>
            </div>
            <div>
                <el-input
                        type="textarea"
                        :autosize="{minRows: 2, maxRows: 4}"
                        placeholder="message"
                        :value="message"
                        name="message"
                        readonly
                >
                </el-input>
            </div>
        </div>
    </div>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex';
  import {types} from "../../store/decision-tree.types";

  const {mapGetters} = createNamespacedHelpers('decisionTree');

  export default {
    props: {
      value: Object,
    },

    data: function () {
      return {
        animationClass: null,
      }
    },

    computed: {
      ...mapGetters(['isNodeCurrent', 'isEditMode']),

      /**
       * @return {String}
       */
      name: function() {
        return this.value.name || '';
      },

      /**
       * @return {String}
       */
      context: function() {
        return this.value.context || '';
      },

      /**
       * @return {String}
       */
      message: function() {
        return this.value.message || '';
      },

      /**
       * @return {Boolean}
       */
      isCurrent: function () {
        return this.isNodeCurrent(this.value);
      },
    },
    methods: {
      /**
       * @return {undefined}
       */
      onCopySuccess() {
        this.animationClass = 'animated heartBeat';
      },

      /**
       * @return {undefined}
       */
      onCopyError() {
        //
      },

      onAnimationEnd() {
        this.animationClass = null;
      },

      edit() {
        this.$root.$emit('show-decision-tree-item-form', {
          type: types.proposition,
          node: _.assign({}, this.value),
        });
      },
    },
  }
</script>
