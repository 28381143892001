export const types = {
  proposition: 'proposition',
  outcome: 'outcome',
};

export const modes = {
  display: 'display',
  edit: 'edit',
};

export const dataStatuses = {
  notFetched: 'notFetched',
  fetching: 'fetching',
  fetched: 'fetched',
  fetchingError: 'fetchingError',
};
