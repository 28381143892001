/**
 * Get a config option by path
 *
 * @param {String} path
 * @param {*} defValue
 * @returns {*}
 */
window.config = function (path, defValue = null) {
  return _.get(window.scriptConfig, path, defValue);
};

/**
 * @param {Number} min
 * @param {Number} max
 * @param {Number} value
 * @returns {Number}
 */
window.between = function(min, max, value) {
  if (value >= max) {
    return max;
  }

  if (value <= min) {
    return min;
  }

  return value;
};