<template>
    <el-dialog
            :visible="true"
            :show-close="false"
            v-if="isVisible"
            width="600px"
            :title="title"
    >
        <div v-loading="isBusy" element-loading-spinner="el-icon-loading">
            <div>
                <el-form
                        :model="node"
                        label-position="left"
                        label-width="100px"
                        size="small"
                        :rules="rules"
                        ref="form"
                >
                    <div v-show="isPropositionVisible">
                        <h6>Proposition</h6>
                        <el-form-item label="Name" prop="name">
                            <el-input v-model="node.name"></el-input>
                        </el-form-item>
                        <el-form-item label="Context" prop="context">
                            <el-input v-model="node.context"></el-input>
                        </el-form-item>
                        <el-form-item label="Message" prop="message">
                            <el-input
                                    v-model="node.message"
                                    type="textarea"
                                    :rows="4"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div v-show="isOutcomeVisible">
                        <h6>Outcome</h6>
                        <el-form-item label="Context" prop="outcome_context">
                            <el-input v-model="node.outcome_context"></el-input>
                        </el-form-item>
                        <el-form-item label="Message" prop="outcome">
                            <el-input
                                    v-model="node.outcome"
                                    type="textarea"
                                    :rows="4"
                            ></el-input>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <div>
                <el-button @click="onSaveButtonClicked">Save</el-button>
                <el-button @click="onCancelButtonClicked">Cancel</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex';
  import {types} from "../../store/decision-tree.types";

  const {mapActions} = createNamespacedHelpers('decisionTree');

  export default {
    data: function () {
      return {
        node: null,
        type: null,
        isBusy: false,
        rules: {
          name: [{validator: this.fieldExternalValidator}],
          outcome: [{validator: this.fieldExternalValidator}],
          outcome_context: [{validator: this.fieldExternalValidator}],
          context: [{validator: this.fieldExternalValidator}],
          message: [{validator: this.fieldExternalValidator}],
        },
        errors: {},
      }
    },

    computed: {
      isVisible() {
        return !_.isNull(this.node);
      },

      typeAsText() {
        if (this.type === types.proposition) {
          return 'proposition';
        } else if (this.type === types.outcome) {
          return 'outcome';
        } else {
          return '';
        }
      },

      title() {
        return _.isInteger(this.node.id)
          ? `Edit the ${this.typeAsText}`
          : `Add a new ${this.typeAsText}`;
      },

      isPropositionVisible() {
        return this.type === types.proposition;
      },

      isOutcomeVisible() {
        return this.type === types.outcome;
      },
    },

    methods: {
      ...mapActions(['store']),

      onItemFormShow({type, node}) {
        this.node = _.assign({}, node);
        this.type = type;
      },

      /**
       * @return {Object}
       */
      getForm() {
        const fields = _.pick(this.node, [
          'parent_id', 'name', 'outcome', 'outcome_context', 'context', 'message', 'reference_id'
        ]);

        return _.assign(fields, {
          _method: this.node.id ? 'PATCH' : 'POST'
        });
      },

      onSaveButtonClicked() {
        this.isBusy = true;

        this.store({
          form: this.getForm(),
          id: this.node.id
        })
          .then(() => this.node = this.type = null)
          .catch(this.handleErrorResponse)
          .then(() => this.isBusy = false);
      },

      /**
       * @param {Object} response
       */
      handleErrorResponse({data, status}) {
        const {errors, message} = data || {};
        if (status === 422 && errors) {
          this.errors = errors;
          this.$refs.form.validate(isValid => isValid);
        } else if (message && _.isString(message)) {
          this.$message.error(message);
        }
      },

      onCancelButtonClicked() {
        this.node = this.type = null;
      },

      /**
       * @param {Object} rule
       * @param {*} value
       * @param {Function} callback
       */
      fieldExternalValidator({field}, value, callback) {
        const errors = _.map(
          _.get(this.errors, field, []), error => new Error(error)
        );
        _.unset(this.errors, field);

        callback(errors);
      },
    },

    mounted() {
      this.$root.$on('show-decision-tree-item-form', this.onItemFormShow);
    },

    beforeDestroy() {
      this.$root.$off('show-decision-tree-item-form', this.onItemFormShow);
    },
  }
</script>
