var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-item outcome",
      class: {
        preselected: _vm.isPreselected,
        selected: _vm.isSelected,
        current: _vm.isCurrent
      },
      on: {
        mouseover: _vm.onMouseOver,
        mouseleave: _vm.onMouseLeave,
        click: _vm.selectOutcome
      }
    },
    [
      _c("div", { staticClass: "clearfix" }, [
        _vm.isEditMode
          ? _c(
              "div",
              { staticClass: "control-panel float-right" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "p-0",
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.editOutcome($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-edit fa-lg" })]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "p-0",
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.confirmDestroying($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-trash-alt fa-lg" })]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.response))]),
      _vm._v(" "),
      _c("div", { staticClass: "outcome-context" }, [
        _c("strong", [_vm._v("Context:")]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.context))])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "outcome-response float-left" }, [
      _c("strong", [_vm._v("Response: ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }