var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      { staticClass: "btn btn-purple", on: { click: _vm.stepBack } },
      [_vm._v("Step Back")]
    ),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-purple", on: { click: _vm.toCurrent } },
      [_vm._v("To Active")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }