<template>
    <div class="decision-tree-group">
        <div class="decision-tree-group-column">
            <transition appear appear-active-class="animated fadeIn" enter-active-class="animated fadeIn">
                <proposition
                        v-if="hasProposition"
                        :value="proposition"
                        :key="proposition.id"
                ></proposition>
            </transition>
        </div>
        <div class="decision-tree-group-column decision-tree-group-container">
            <transition-group tag="div" appear appear-active-class="animated fadeIn" enter-active-class="animated fadeIn" >
                <outcome
                        v-for="outcome in outcomes"
                        :key="outcome.id"
                        :value="outcome"
                        @outcome-selected="onOutcomeSelected"
                ></outcome>
            </transition-group>

            <add-item-placeholder
                    v-if="isEditMode"
                    @click.native="addNewOutcome"
            ></add-item-placeholder>
        </div>
        <div class="decision-tree-group-column">
            <transition leave-active-class="animated fadeOut">
                <decision-tree-group
                        v-if="hasSelectedOutcome"
                        :proposition="selectedOutcome"
                        @tree-node-selected="onTreeNodeSelected"
                ></decision-tree-group>
            </transition>
        </div>
    </div>
</template>

<script>
  import Outcome from './outcome';
  import Proposition from './proposition';
  import AddItemPlaceholder from './add-item-placeholder';
  import {createNamespacedHelpers} from 'vuex';
  import {types} from "../../store/decision-tree.types";

  const {mapGetters, mapState, mapMutations} = createNamespacedHelpers('decisionTree');

  export default {
    props: {
      proposition: Object,
    },

    mounted() {
      if (this.isCurrent) {
        this.setDomElement(this.$el);
      }
    },

    computed: {
      ...mapGetters(['getChildren', 'isDisplayMode', 'isEditMode', 'isNodeCurrent']),
      ...mapState(['path']),

      /**
       * @return {Boolean}
       */
      hasSelectedOutcome() {
        return !_.isEmpty(this.selectedOutcome);
      },

      /**
       * @return {Object|undefined}
       */
      selectedOutcome() {
        return _.find(this.outcomes, outcome => _.includes(this.path, outcome.id));
      },

      /**
       * @return {Boolean}
       */
      isCurrent() {
        return this.isNodeCurrent(this.proposition);
      },

      /**
       * @return {Boolean}
       */
      hasProposition() {
        return !_.isEmpty(this.proposition);
      },

      /**
       * @return {Array|null}
       */
      outcomes() {
        return this.getChildren(this.proposition);
      },
    },

    watch: {
      isCurrent: function (newVal) {
        if (newVal) {
          this.setDomElement(this.$el);
        }
      }
    },

    methods: {
      ...mapMutations(['setDomElement']),

      /**
       * @return {undefined}
       */
      onOutcomeSelected({id}) {
        if (this.isDisplayMode && this.hasSelectedOutcome) {
          return;
        }

        this.$emit('tree-node-selected', {
          ids: [id],
        });
      },

      /**
       * @return {undefined}
       */
      onTreeNodeSelected({ids}) {
        this.$emit('tree-node-selected', {
          ids: _.concat(ids, [this.selectedOutcome.id])
        });
      },

      /**
       * @return {undefined}
       */
      addNewOutcome() {
        this.$root.$emit('show-decision-tree-item-form', {
          type: types.outcome,
          node: {
            parent_id: this.proposition.id,
          },
        });
      },
    },

    components: {
      'proposition': Proposition,
      'outcome': Outcome,
      'add-item-placeholder': AddItemPlaceholder,
    },
  }
</script>

<style scoped>

</style>