var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-item proposition",
      class: { current: _vm.isCurrent }
    },
    [
      _c("div", { staticClass: "clearfix" }, [
        _vm.isEditMode
          ? _c(
              "div",
              { staticClass: "control-panel float-right" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "p-0",
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.edit($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-edit fa-lg" })]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.name))]),
      _vm._v(" "),
      _c("div", { staticClass: "proposition-context" }, [
        _c("strong", [_vm._v("Context:")]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.context))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "proposition-message" }, [
        _c("div", { staticClass: "clearfix" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard",
                      value: _vm.message,
                      expression: "message"
                    }
                  ],
                  staticClass: "p-1 copy-button",
                  class: _vm.animationClass,
                  attrs: { type: "text" },
                  on: { success: _vm.onCopySuccess, error: _vm.onCopyError },
                  nativeOn: {
                    animationend: function($event) {
                      return _vm.onAnimationEnd($event)
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-copy fa-lg" })]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                type: "textarea",
                autosize: { minRows: 2, maxRows: 4 },
                placeholder: "message",
                value: _vm.message,
                name: "message",
                readonly: ""
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "proposition-name float-left" }, [
      _c("strong", [_vm._v("Name:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "float-left" }, [
      _c("strong", [_vm._v("Message:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }