<template>
    <div
            class="column-item outcome"
            v-bind:class="{preselected: isPreselected, selected:isSelected, current:isCurrent}"
            @mouseover="onMouseOver"
            @mouseleave="onMouseLeave"
            @click="selectOutcome"
    >
        <div class="clearfix">
            <div class="control-panel float-right" v-if="isEditMode">
                <el-button type="text" @click.stop="editOutcome" class="p-0"><i class="fas fa-edit fa-lg"></i>
                </el-button>
                <el-button type="text" @click.stop="confirmDestroying" class="p-0"><i
                        class="fas fa-trash-alt fa-lg"></i>
                </el-button>
            </div>
            <div class="outcome-response float-left"><strong>Response: </strong></div>
        </div>
        <p>{{response}}</p>
        <div class="outcome-context">
            <strong>Context:</strong>
            <p class="mb-0">{{context}}</p>
        </div>
    </div>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex';
  import {types} from "../../store/decision-tree.types";

  const {mapGetters, mapActions} = createNamespacedHelpers('decisionTree');

  export default {
    props: {
      value: Object,
    },

    data: function () {
      return {
        isPreselected: false,
      }
    },

    computed: {
      ...mapGetters(['isNodeCurrent', 'isNodeSelected', 'isEditMode', 'isNodeCurrent']),

      /**
       * @return {String}
       */
      response: function () {
        return this.value.outcome || '';
      },

      /**
       * @return {String}
       */
      context: function () {
        return this.value.outcome_context || '';
      },

      /**
       * @return {Boolean}
       */
      isSelected() {
        return this.isNodeSelected(this.value);
      },

      /**
       * @return {Boolean}
       */
      isCurrent: function () {
        return this.isNodeCurrent(this.value);
      },
    },

    methods: {
      ...mapActions(['destroy']),

      /**
       * @return {undefined}
       */
      onMouseOver() {
        this.isPreselected = true;
      },

      /**
       * @return {undefined}
       */
      onMouseLeave() {
        this.isPreselected = false;
      },

      /**
       * @return {undefined}
       */
      selectOutcome() {
        this.$emit('outcome-selected', this.value);
      },

      /**
       * @return {undefined}
       */
      editOutcome() {
        this.$root.$emit('show-decision-tree-item-form', {
          type: types.outcome,
          node: this.value,
        });
      },

      /**
       * @return {undefined}
       */
      confirmDestroying() {
        this.$confirm(
          'This will permanently delete the outcome with a related proposition and all nested elements. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
          .then(() => this.destroyOutcome())
          .catch(() => {});
      },

      /**
       * @return {undefined}
       */
      destroyOutcome() {
        this.destroy({id: this.value.id})
          .then(() => this.$message({type: 'success', message: 'Successfully deleted!'}))
          .catch(({message}) => this.$message({type: 'error', message: message || 'Deleting error!'}));
      },
    },
  }
</script>
