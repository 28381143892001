import UrlParse from 'url-parse';
import QueryString from 'qs';

/**
 * @param {Object} parent
 * @param {Array} ids
 * @param {Array} nodes
 * @param {Array} validated
 * @return {Array}
 */
export function validateIds(parent, ids, nodes, validated = []) {
  const id = ids.shift();
  const child = _.find(nodes, node => node.id === id);
  if (!child || child.parent_id !== parent.id) {
    return validated;
  }

  return validateIds(child, ids, nodes, _.concat(validated, id));
}

/**
 * @param {Object} params
 * @return {undefined}
 */
export function updateUrl(params) {
  window.history.replaceState({}, '', generateNewUrl(params));
}

/**
 * @param {Object} params
 * @return {string}
 */
function generateNewUrl({path}) {
  const url = new UrlParse(window.location);

  const query = _.omit(
    QueryString.parse(_.trimStart(url.query, '?')),
    ['path']
  );

  if (!_.isEmpty(path)) {
    query.path = path;
  }

  url.query = QueryString.stringify(query);

  return url.toString();
}


export default {
  validateIds,
  updateUrl
}