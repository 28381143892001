var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "decision-tree-group" }, [
    _c(
      "div",
      { staticClass: "decision-tree-group-column" },
      [
        _c(
          "transition",
          {
            attrs: {
              appear: "",
              "appear-active-class": "animated fadeIn",
              "enter-active-class": "animated fadeIn"
            }
          },
          [
            _vm.hasProposition
              ? _c("proposition", {
                  key: _vm.proposition.id,
                  attrs: { value: _vm.proposition }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "decision-tree-group-column decision-tree-group-container"
      },
      [
        _c(
          "transition-group",
          {
            attrs: {
              tag: "div",
              appear: "",
              "appear-active-class": "animated fadeIn",
              "enter-active-class": "animated fadeIn"
            }
          },
          _vm._l(_vm.outcomes, function(outcome) {
            return _c("outcome", {
              key: outcome.id,
              attrs: { value: outcome },
              on: { "outcome-selected": _vm.onOutcomeSelected }
            })
          }),
          1
        ),
        _vm._v(" "),
        _vm.isEditMode
          ? _c("add-item-placeholder", {
              nativeOn: {
                click: function($event) {
                  return _vm.addNewOutcome($event)
                }
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "decision-tree-group-column" },
      [
        _c(
          "transition",
          { attrs: { "leave-active-class": "animated fadeOut" } },
          [
            _vm.hasSelectedOutcome
              ? _c("decision-tree-group", {
                  attrs: { proposition: _vm.selectedOutcome },
                  on: { "tree-node-selected": _vm.onTreeNodeSelected }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }